import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

import auth from './modules/auth';
import admin from './modules/admin';
import company from './modules/company';
import contentProvider from './modules/content-provider';
import messages from './modules/messages';
import packages from './modules/packages';
import products from './modules/products';
import roles from './modules/roles';
import settings from './modules/settings';
import subscriptions from './modules/subscriptions';
import unionLinks from './modules/union-link';
import user from './modules/user';
import users from './modules/users';
import priceplans from './modules/priceplans';

Vue.use(Vuex);
Vue.use(Cookies);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    namespaced: true,
    auth,
    admin,
    company,
    priceplans,
    contentProvider,
    messages,
    packages,
    products,
    roles,
    settings,
    subscriptions,
    unionLinks,
    user,
    users,
  },
  strict: false,
});

export default store;
