import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import Full from '@/container/Full';
const Public = () => import(/* webpackChunkName: "pages" */ '@/container/Public');
// GeneralViews
const PageNotFound = () => import(/* webpackChunkName: "pages" */ '@/views/pages/NotFoundPage');

// Dashboard
const Dashboard = () => import(/* webpackChunkName: "pages" */ '@/views/dashboard/Dashboard');

// User Profile
const Profile = () => import(/* webpackChunkName: "pages" */ '@/views/user/Profile');

// Organization
const OrganizationFull = () => import(/* webpackChunkName: "super-admin" */ '@/views/organization/OrganizationFull');
const OrganizationList = () => import(/* webpackChunkName: "super-admin" */ '@/views/organization/OrganizationList');
const OrganizationCreate = () => import(/* webpackChunkName: "super-admin" */ '@/views/organization/OrganizationCreate');
const OrganizationEdit = () => import(/* webpackChunkName: "super-admin" */ '@/views/organization/OrganizationEdit');

//Subscription
const SubscriptionFull = () => import(/* webpackChunkName: "products" */ '@/views/subscription/SubscriptionFull');
const SubscriptionList = () => import(/* webpackChunkName: "products" */ '@/views/subscription/SubscriptionList');
// import SubscriptionForm from '@/views/subscription/SubscriptionForm'

//Products
const ProductFull = () => import(/* webpackChunkName: "products" */ '@/views/products/ProductFull');
const ProductList = () => import(/* webpackChunkName: "products" */ '@/views/products/ProductList');
const ProductForm = () => import(/* webpackChunkName: "products" */ '@/views/products/ProductForm');

// Roles and permissions // Admin Roles
/*import RolesFull from '@/views/admin-roles/RolesFull'
import RolesList from '@/views/admin-roles/RolesList'
import RolesForm from '@/views/admin-roles/RolesForm'
import RolesFormEdit from '@/views/admin-roles/RolesFormEdit'*/

// Admins
const AdminsFull = () => import(/* webpackChunkName: "super-admin" */ '@/views/admin/AdminsFull');
const AdminList = () => import(/* webpackChunkName: "super-admin" */ '@/views/admin/AdminList');
const AdminCreate = () => import(/* webpackChunkName: "super-admin" */ '@/views/admin/AdminFormNew');
const AdminEdit = () => import(/* webpackChunkName: "super-admin" */ '@/views/admin/AdminFormEdit');

//Message
const MessagesFull = () => import(/* webpackChunkName: "tools" */ '@/views/message/MessagesFull');
const Notifications = () => import(/* webpackChunkName: "tools" */ '@/views/message/Notifications');
const NotificationsForm = () => import(/* webpackChunkName: "tools" */ '@/views/message/NotificationsForm');
const MessageSequence = () => import(/* webpackChunkName: "tools" */ '@/views/message/MessageSequence');
const MessageSequenceForm = () => import(/* webpackChunkName: "tools" */ '@/views/message/MessageSequenceForm');

const UploadFileFull = () => import(/* webpackChunkName: "tools" */ '@/views/upload-file/UploadFileFull');
const UploadFileList = () => import(/* webpackChunkName: "tools" */ '@/views/upload-file/UploadFileList');
const UploadFileForm = () => import(/* webpackChunkName: "tools" */ '@/views/upload-file/UploadFileForm');

// Content provider
const CPFull = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPFull');
const CPList = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPList');
const CPForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPForm');
const CPPricingForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPPricingForm');
// Grups
const CPGroupList = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPGroupList');
const CPGroupForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPGroupForm');
// Categories
const CPCategoryList = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPCategoryList');
const CPCategoryForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPCategoryForm');
// Types
const CPTypeList = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPTypeList');
const CPTypeForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPTypeForm');
// Union Types
const CPUnionTypeList = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPUnionTypeList');
const CPUniontypeForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/content-providers/CPUniontypeForm');

// Member Benefits (Package)
const PackageFull = () => import(/* webpackChunkName: "content-providers" */ '@/views/member-benefits/PackageFull');
const PackageList = () => import(/* webpackChunkName: "content-providers" */ '@/views/member-benefits/PackageList');
const PackageForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/member-benefits/PackageForm');

//Register from SMS
const RegisterSms = () => import(/* webpackChunkName: "content-providers" */ '@/views/pages/RegisterSms');

//Channel Invite Page
const ChannelInvitePage = () => import(/* webpackChunkName: "content-providers" */ '@/views/pages/ChannelInvitePage');

// Public Provisioning Form
const ProvisioningForm = () => import(/* webpackChunkName: "content-providers" */ '@/views/pages/ProvisioningForm');

//Account Verification
const AccountVerification = () => import(/* webpackChunkName: "content-providers" */ '@/views/pages/AccountVerification');

// Union Links
const UnionLinksFull = () => import(/* webpackChunkName: "union-links" */ '@/views/union-links/UnionLinksFull');
const UnionLinksList = () => import(/* webpackChunkName: "union-links" */ '@/views/union-links/UnionLinksList');
const UnionLinksForm = () => import(/* webpackChunkName: "union-links" */ '@/views/union-links/UnionLinksForm');
const UnionLinkTypes = () => import(/* webpackChunkName: "union-links" */ '@/views/union-links/UnionLinkTypes');

// Pages
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/pages/Login');
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '@/views/pages/ForgotPassword');

// Registered Users
const UserList = () => import(/* webpackChunkName: "users" */ '@/views/users/UserList');
const UserGroupsList = () => import(/* webpackChunkName: "users" */ '@/views/users/UserGroupsList');
const UserIMList = () => import(/* webpackChunkName: "users" */ '@/views/users/UsersImList.vue');
const UserGroupsForm = () => import(/* webpackChunkName: "users" */ '@/views/users/UserGroupsForm');
const UserRecoveredList = () => import(/* webpackChunkName: "users" */ '@/views/users/UserRecoveredList');

// Campaigns
const Campaigns = () => import(/* webpackChunkName: "tools" */ '@/views/message/Campaigns');
const CampaignsForm = () => import(/* webpackChunkName: "tools" */ '@/views/message/CampaignsForm');

const Settings = () => import(/* webpackChunkName: "settings" */ '@/views/settings/Settings');
const CompanySettings = () => import(/* webpackChunkName: "settings" */ '@/views/settings/CompanySettings');
const ReplicaBuilds = () => import(/* webpackChunkName: "settings" */ '@/views/settings/ReplicaBuilds');
const AuxiliaryBuilds = () => import(/* webpackChunkName: "settings" */ '@/views/settings/AuxiliaryBuilds');
const Provisioning = () => import(/* webpackChunkName: "settings" */ '@/views/settings/Provisioning.vue');
const Stripe = () => import(/* webpackChunkName: "settings" */ '@/views/settings/Stripe.vue');

// Price Plans
const PricePlansFull = () => import(/* webpackChunkName: "tools" */ '@/views/price-plans/PricePlansFull');
const PricePlansList = () => import(/* webpackChunkName: "tools" */ '@/views/price-plans/PricePlansList');
const PricePlansEdit = () => import(/* webpackChunkName: "tools" */ '@/views/price-plans/PricePlansEdit');
const PricePlansCreate = () => import(/* webpackChunkName: "tools" */ '@/views/price-plans/PricePlansCreate');

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (to.name === 'ChannelInvitationLink' || to.name === 'ChannelInvitationLinkConfirmation') {
    next();
    return;
  }

  if (!store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next();
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const autoLogin = urlParams.get('autoLogin');
  if (autoLogin) {
    const path = to.path;
    next(`/login?autoLogin=${autoLogin}&redirect=${path}`);
  } else {
    next('/login');
  }
};

const notVerified = (to, from, next) => {
  const userEmail = store.getters['auth/userEmail'];
  const registerUserEmail = store.getters['register/registerUserEmail'];
  if ((userEmail !== undefined && userEmail !== '') || registerUserEmail || (to.name === 'VerifyToken' && to.params.token)) {
    next();
    return;
  }
  next('/login');
};

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: Full,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          beforeEnter: ifAuthenticated,
        },
        // Oganizations
        {
          path: 'organizations',
          name: 'Organizations',
          redirect: { path: '/organizations' },
          component: OrganizationFull,
          meta: { access: ['company'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: '/organizations',
              name: 'Organization List',
              component: OrganizationList,
              meta: { access: ['company'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Organization',
              component: OrganizationEdit,
              meta: { access: ['company'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Organization',
              component: OrganizationCreate,
              meta: { access: ['company'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Price Plans
        {
          path: 'priceplans',
          name: 'Price Plans',
          redirect: { path: '/priceplans' },
          component: PricePlansFull,
          meta: { access: ['company'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: '/priceplans',
              name: 'Price Plans List',
              component: PricePlansList,
              meta: { access: ['company'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit price plans',
              component: PricePlansEdit,
              meta: { access: ['company'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create price plan',
              component: PricePlansCreate,
              meta: { access: ['company'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Oganizations
        {
          path: 'subscriptions',
          name: 'User Subscriptions',
          redirect: { path: '/subscriptions' },
          component: SubscriptionFull,
          meta: { access: ['subscription'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: '/subscriptions',
              name: 'User Subscription List',
              component: SubscriptionList,
              meta: { access: ['subscription'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Products
        {
          path: 'products',
          name: 'Subscriptions',
          redirect: { path: '/products/list' },
          component: ProductFull,
          meta: { access: ['products'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Subscription List',
              path: '/products/list/:companyID',
              component: ProductList,
              meta: { access: ['products'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Add Subscription',
              path: '/products/create/:companyID',
              component: ProductForm,
              meta: { access: ['products'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Update Product',
              path: '/products/edit/:companyID/:id',
              component: ProductForm,
              meta: { access: ['products'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Admin Users
        {
          path: 'user/admins',
          name: 'Admin Users',
          redirect: { path: '/user/admins' },
          component: AdminsFull,
          meta: { access: ['createAdmin'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Admin List',
              path: '/user/admins',
              component: AdminList,
              meta: { access: ['createAdmin'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'edit/:id',
              name: 'Edit Admin',
              component: AdminEdit,
              meta: { access: ['createAdmin'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'create',
              name: 'Create Admin',
              component: AdminCreate,
              meta: { access: ['createAdmin'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        {
          path: 'upload-file',
          name: 'File Manager',
          redirect: { path: '/upload-file/list' },
          component: UploadFileFull,
          meta: { access: ['audioManager'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Upload File List',
              path: '/upload-file/list',
              component: UploadFileList,
              meta: { access: ['audioManager'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Upload File',
              path: '/upload-file/create',
              component: UploadFileForm,
              meta: { access: ['audioManager'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Update File',
              path: '/upload-file/edit/:id',
              component: UploadFileForm,
              meta: { access: ['audioManager'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Messages
        {
          path: 'messages',
          name: 'Messages',
          redirect: { path: '/messages/history' },
          component: MessagesFull,
          meta: { access: ['messages'] },
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'history',
              name: 'Message History',
              component: Notifications,
              meta: { access: ['messages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'send-notifications',
              name: 'Send Message',
              component: NotificationsForm,
              meta: { access: ['messages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'award-campaigns',
              name: 'Campaigns',
              component: Campaigns,
              meta: { access: ['messages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Create Campaign',
              path: 'award-campaigns/create',
              component: CampaignsForm,
              meta: { access: ['messages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Update Campaign',
              path: 'award-campaigns/edit/:campaignID',
              component: CampaignsForm,
              meta: { access: ['messages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'sequence/list',
              name: 'Message Sequence List',
              component: MessageSequence,
              meta: { access: ['messagesSequence'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'sequence/create',
              name: 'Create Message Sequence',
              component: MessageSequenceForm,
              meta: { access: ['messagesSequence'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Content Providers
        {
          path: 'content-provider',
          name: 'Content Provider',
          redirect: { path: '/content-provider/list' },
          component: CPFull,
          meta: { access: ['contentProviders'] },
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'list',
              name: 'Content Provider List',
              component: CPList,
              meta: { access: ['contentProvider'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/create',
              name: 'Create Content Provider',
              component: CPForm,
              meta: { access: ['contentProvider'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/pricing-panel/:id',
              name: 'Pricing Panel',
              component: CPPricingForm,
              meta: { access: ['contentProvider'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/edit/:id',
              name: 'Update Content Provider',
              component: CPForm,
              meta: { access: ['contentProvider'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'groups',
              name: 'CP Groups',
              component: CPGroupList,
              meta: { access: ['cpTypes'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/groups/:action',
              name: 'CP Create Group',
              component: CPGroupForm,
              meta: { access: ['cpGroups'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/groups/:action/:id',
              name: 'CP Update Group',
              component: CPGroupForm,
              meta: { access: ['cpGroups'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'categories',
              name: 'Categories',
              component: CPCategoryList,
              meta: { access: ['cpCategories'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/categories/:action',
              name: 'Create Category',
              component: CPCategoryForm,
              meta: { access: ['cpCategories'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/categories/:action/:id',
              name: 'Update Category',
              component: CPCategoryForm,
              meta: { access: ['cpCategories'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'types',
              name: 'Types',
              component: CPTypeList,
              meta: { access: ['cpTypes'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/types/:action',
              name: 'Create Type',
              component: CPTypeForm,
              meta: { access: ['cpTypes'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/types/:action/:id',
              name: 'Update Type',
              component: CPTypeForm,
              meta: { access: ['cpTypes'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: 'union-types',
              name: 'Union Types',
              component: CPUnionTypeList,
              meta: { access: ['cpUnionType'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/union-types/:action',
              name: 'Create Union Type',
              component: CPUniontypeForm,
              meta: { access: ['cpUnionType'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/content-provider/union-types/:action/:id',
              name: 'Update Union Type',
              component: CPUniontypeForm,
              meta: { access: ['cpUnionType'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Union Links
        {
          path: 'link',
          name: 'Links',
          redirect: { path: '/links' },
          component: UnionLinksFull,
          meta: { access: ['unionLinks'] },
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '/links',
              name: 'Link',
              component: UnionLinksForm,
              meta: { access: ['unionLinks'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/links/:id',
              name: 'Update Link',
              component: UnionLinksForm,
              meta: { access: ['unionLinks'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/links/types/list',
              name: 'Screen Banners',
              component: UnionLinkTypes,
              meta: { access: ['unionLinks'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Member Benefits
        {
          path: 'packages',
          name: 'Member Benefits',
          redirect: { path: '/packages/list' },
          component: PackageFull,
          meta: { access: ['packages'] },
          beforeEnter: ifAuthenticated,
          children: [
            {
              name: 'Member Benefits List',
              path: '/packages/list',
              component: PackageList,
              meta: { access: ['packages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Create Member Benefits',
              path: '/packages/create',
              component: PackageForm,
              meta: { access: ['packages'] },
              beforeEnter: ifAuthenticated,
            },
            {
              name: 'Update Member Benefits',
              path: '/packages/edit/:id',
              component: PackageForm,
              meta: { access: ['packages'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // Mobile Users
        {
          path: 'users',
          redirect: '/users',
          name: 'Registered Users',
          beforeEnter: ifAuthenticated,
          meta: { access: ['userGroup'] },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '/users',
              name: 'User List',
              component: UserList,
              meta: { access: ['profile', 'userList'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/users/groups',
              name: 'Groups',
              component: UserGroupsList,
              meta: { access: ['userGroup'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/users/im',
              name: 'IM',
              component: UserIMList,
              meta: { access: ['profile', 'userList'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/users/groups/create',
              name: 'Group',
              component: UserGroupsForm,
              meta: { access: ['userGroup'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/users/groups/:action/:cpID',
              name: 'Create Group',
              component: UserGroupsForm,
              meta: { access: ['userGroup'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/users/groups/:action/:cpID/:groupID',
              name: 'Update Group',
              component: UserGroupsForm,
              meta: { access: ['userGroup'] },
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/users/recovered',
              name: 'Re-registered',
              component: UserRecoveredList,
              meta: { access: ['profile', 'userList'] },
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // CompanySettings
        {
          path: 'settings',
          name: 'Company Settings',
          beforeEnter: ifAuthenticated,
          meta: { access: ['CompanySettings'] },
          component: CompanySettings,
        },
        // Settings
        {
          path: 'other-settings',
          redirect: '/other-settings',
          name: 'Settings',
          beforeEnter: ifAuthenticated,
          meta: { access: ['settings'] },
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '/other-settings',
              name: 'Other Settings',
              beforeEnter: ifAuthenticated,
              component: Settings,
            },
            {
              path: '/other-settings/replica-builds',
              name: 'Replica Builds',
              component: ReplicaBuilds,
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/other-settings/auxiliary-builds',
              name: 'Auxiliary Builds',
              component: AuxiliaryBuilds,
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/other-settings/provisioning',
              name: 'Provisioning',
              component: Provisioning,
              beforeEnter: ifAuthenticated,
            },
            {
              path: '/other-settings/stripe',
              name: 'Stripe',
              component: Stripe,
              beforeEnter: ifAuthenticated,
            },
          ],
        },
        // User Profile
        {
          path: 'user',
          redirect: '/user/profile',
          name: 'User',
          beforeEnter: ifAuthenticated,
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'profile',
              name: 'Profile',
              component: Profile,
              beforeEnter: ifAuthenticated,
            },
          ],
        },
      ],
    },
    {
      path: '/provision/:companyID',
      component: Public,
      beforeEnter: ifNotAuthenticated,
      children: [{ path: '/', name: 'Provisioning Form', component: ProvisioningForm }],
    },
    {
      path: '/:companyID',
      component: Public,
      beforeEnter: ifNotAuthenticated,
      children: [
        { path: '/', name: 'Company Login', component: Login },
        { path: '/:companyID/register/sms/:token', name: 'Register SMS', component: RegisterSms },
        { path: '/:companyID/:inviteToken', name: 'ChannelInvitationLink', component: ChannelInvitePage },
        { path: '/:companyID/:hashID/confirm', name: 'ChannelInvitationLinkConfirmation', component: ChannelInvitePage },
        { path: '/:companyID/verify-sending-email/:messageID', name: 'Account Verification', component: AccountVerification },
      ],
    },
    {
      path: '/login',
      component: Public,
      beforeEnter: ifNotAuthenticated,
      children: [{ path: '/', name: 'Login', component: Login }],
    },
    {
      path: '/password/reset/:type',
      component: Public,
      beforeEnter: ifNotAuthenticated,
      children: [
        { path: '/', name: 'Reset Password', component: ForgotPassword },
        { path: '/password/reset/:type/:token', name: 'Forgot Password', component: ForgotPassword },
      ],
    },

    /*{
      path: '/register',
      name: 'Register',
      component: Register
    },*/
    { path: '*', name: 'PageNotFound', component: PageNotFound },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/notFound');
  } else {
    next();
  }
});

export default router;
