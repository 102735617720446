<template>
  <div id="mainWrapper" class="wrapper">
    <notifications></notifications>
    <dashboard-navbar id="navbar" :type="$route.meta.navbarType"></dashboard-navbar>
    <side-bar id="navbar2" v-if="navItems.length === 0">
      <template #dropdown-list>
        <div class="col-lg-12">
          <b-form-group v-if="currentUserGroup === 1 && companies.length > 1" role="group" class="m-0 has-feedback">
            <base-input class="mt-3 mb-0">
              <b-form-select
                id="companyID"
                v-model="selectedCompany"
                :options="companies"
                name="companyID"
                placeholder="Select Organization"
                class="form-control m-0"
                @change="onCompanyChange($event)">
              </b-form-select>
            </base-input>
          </b-form-group>
          <content-loader
            v-else-if="currentUserGroup === 1"
            :height="46"
            :width="216"
            :speed="1"
            primary-color="#f3f3f3"
            secondary-color="#dce2fc"
            style="margin-top: 10px">
            <rect x="0" y="3.67" rx="0" ry="0" width="216" height="46" />
          </content-loader>
        </div>
      </template>
      <SideBarLoader style="padding-left: 10px; height: 600px; margin-top: -12px" :user-group="currentUserGroup"> </SideBarLoader>
      <template v-if="links.length > 0 && navItems.length > 0" #links-after>
        <ul class="navbar-nav">
          <hr class="my-2" />
          <h6 class="navbar-heading p-0 text-gray">Links</h6>
          <li v-for="(link, index) in links" :key="index" class="nav-item">
            <a class="nav-link" :href="link.link" target="_blank">
              <i v-if="link.iconFileName === ''" class="fas fa-external-link-alt" :style="iconColor"></i>
              <span v-else class="iconImg"><img :src="link.icon" /></span>
              <span class="nav-link-text">{{ link.title }}</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <side-bar id="navbar3" v-else-if="!companyLoading">
      <template #dropdown-list>
        <div class="col-lg-12">
          <b-form-group v-if="currentUserGroup === 1 && companies.length > 1" role="group" class="m-0 has-feedback">
            <base-input class="mt-3 mb-0">
              <b-form-select
                id="companyID"
                v-model="selectedCompany"
                :options="companies"
                name="companyID"
                placeholder="Select Organization"
                class="form-control m-0"
                @change="onCompanyChange($event)">
              </b-form-select>
            </base-input>
          </b-form-group>
          <content-loader
            v-else-if="currentUserGroup === 1"
            :height="46"
            :width="216"
            :speed="1"
            primary-color="#f3f3f3"
            secondary-color="#dce2fc"
            style="margin-top: 10px">
            <rect x="0" y="3.67" rx="0" ry="0" width="216" height="46" />
          </content-loader>
        </div>
      </template>
      <template v-for="(item, index) in navItems" slot="links">
        <hr v-if="item.separator" :key="item.id + index" class="my-2" />
        <div v-if="item.spacer && links.length === 0" :key="item.id + index" class="my-8"></div>
        <h6 v-else-if="item.title" :key="item.id + index" class="navbar-heading p-0 text-gray">
          {{ item.name }}
        </h6>
        <div v-else-if="item.url" :key="item.id + index">
          <sidebar-item
            :link="{
              name: item.name,
              icon: item.icon + ' text-primary',
              path: item.url,
            }"
            :icon-color="iconColor">
          </sidebar-item>
        </div>
        <div v-else :key="index + item.id">
          <sidebar-item
            :link="{
              name: item.name,
              icon: item.icon + ' text-primary',
            }"
            :icon-color="iconColor">
            <div v-if="item.children" :style="{ overflow: 'scroll' }">
              <sidebar-item
                v-for="(subItem, subindex) in item.children"
                :key="subItem.name + subindex"
                :link="{
                  name: subItem.name,
                  path: subItem.url,
                  icon: subItem.icon + ' text-primary',
                }"
                :icon-color="iconColor" />
            </div>
          </sidebar-item>
        </div>
      </template>
      <template v-if="links.length > 0 && navItems.length > 0" #links-after>
        <ul class="navbar-nav">
          <hr class="my-2" />
          <h6 class="navbar-heading p-0 text-gray">Links</h6>
          <li v-for="(link, index) in links" :key="index" class="nav-item">
            <a class="nav-link" :href="link.link" target="_blank">
              <i v-if="link.iconFileName === ''" class="fas fa-external-link-alt" :style="iconColor"></i>
              <span v-else class="iconImg"><img :src="link.icon" /></span>
              <span class="nav-link-text">{{ link.title }}</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <div id="mainContent" :class="mainContentClass">
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <b-modal
      id="browser_warning"
      ref="browser_warning"
      title="Warning"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      :header-text-variant="'light'"
      hide-footer
      :header-bg-variant="'warning'">
      <div class="p-2 pl-3 w-100 title-temp">
        <h4 class="w-75">Warning</h4>
        <a class="closebtn" @click="closeWarning">×</a>
      </div>
      <div class="body-temp d-block text-center w-100">For best results please use latest version of Chrome browser</div>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable no-new */
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import config from 'config';
import { decoder } from '@/utils/sessions/jwt';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import nav from '../_nav';
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import SideBarLoader from '@/components/ContentLoader/SideBarLoader';
import { FadeTransition } from 'vue2-transitions';
import { ContentLoader } from 'vue-content-loader';
import { STATIC_URL } from '@/api/constants/baseUrl';
import { NotificationTypes, wrapNotification } from '@/utils/notification';

Vue.use(Cookies);

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  name: 'DashboardLayout',
  components: {
    ContentLoader,
    DashboardNavbar,
    ContentFooter,
    // DashboardContent,
    FadeTransition,
    SideBarLoader,
  },
  data() {
    return {
      messagingOnly: false,
      navItems: [],
      navList: [],
      staticUrl: STATIC_URL,
    };
  },
  computed: {
    mainContentClass() {
      return this.$router.currentRoute.query.autoLogin ? ' ' : 'main-content';
    },
    companyLoading: {
      get: function () {
        this.initScrollbar();
        return this.$store.state.company.loading;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    profileLoading() {
      return this.$store.state.user.loading || '';
    },
    companies() {
      const companyList = Vue.lodash.filter(this.companyList, { isActive: 1 });
      const data = companyList.map((company) => {
        return { value: company.companyID, text: company.name };
      });

      const companies = data.sort(function (a, b) {
        return a.text.localeCompare(b.text);
      });

      return [{ value: null, text: 'Select Organization' }, ...companies];
    },
    companyList: {
      get: function () {
        return this.$store.state.company.companyList;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    selectedCompany: {
      get: function () {
        return this.$store.state.company.selectedCompany || Cookies.get('companyID') || null;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    currentCompany() {
      return this.$store.state.user.companyID;
    },
    currentUserGroup() {
      return this.$store.state.user.userGroup || null;
    },
    logo() {
      let company = this.$store.state.user.company || null;

      if (this.selectedCompany == null && (company === null || company === '')) {
        return {
          srcDefault: '/img/brand/logo.png',
          nameDefault: 'Vobo',
          titleDefault: 'Vobo',
        };
      } else {
        return {
          src: company !== null ? (typeof company.logo !== 'undefined' ? company.logo : '') : '/img/brand/logo.png',
          name: company !== null ? company.name : '',
          title: company !== null ? company.name : '',
        };
      }
    },
    iconColor: {
      get: function () {
        // console.log(this.$store.state.company.company)
        let adminColor = Cookies.get('adminColor');
        return `color: ${adminColor} !important` || '';
      },
      set: function (newValue) {
        return newValue;
      },
    },
    links: {
      get: function () {
        const isFeaturedLinksEnable = this.$root.$store.state.user.featuredLinks || false;
        const company = this.$root.$store.state.company.company || [];
        const menuFeatureLink = this.$root.$store.state.user.menuFeatureLink;
        var menuLinks = [];
        var settingFeatureLinkItem = {};

        menuLinks = 'featuredLinks' in company && isFeaturedLinksEnable ? company.featuredLinks : [];

        if (Object.keys(menuFeatureLink).length > 0) {
          settingFeatureLinkItem = menuFeatureLink;
          settingFeatureLinkItem.icon = 'fas fa-external-link-alt';
          settingFeatureLinkItem.iconFileName = '';
          settingFeatureLinkItem.menuFeatureLink = true;
        }

        if (menuLinks.length > 0) {
          const isMenuFeatureLinkFound = menuLinks.filter((menu) => {
            return menu.menuFeatureLink === true;
          });

          if (isMenuFeatureLinkFound.length === 0 && Object.keys(settingFeatureLinkItem).length > 0) {
            menuLinks.push(settingFeatureLinkItem);
          }
        } else {
          if (Object.keys(settingFeatureLinkItem).length > 0) {
            menuLinks.push(settingFeatureLinkItem);
          }
        }

        return menuLinks || [];
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch: {
    selectedCompany: {
      immediate: true,
      handler(companyID) {},
    },
    $route(to, from) {
      const currentRoute = this.$router.currentRoute;
      const allowedRoutes = [
        'Dashboard',
        'Send Message',
        'Message History',
        'Wave Management',
        'Video Management',
        'HTML/PDF Files',
        'Upload Wave File',
        'Update Wave File',
        'Upload Video File',
        'Update Video File',
        'Upload HTML/PDF File',
        'Update HTML/PDF File',
        'Profile',
      ];

      if (this.messagingOnly && !allowedRoutes.includes(currentRoute.name)) {
        this.$router.push('/dashboard');
      }
    },
  },
  mounted() {
    const token = Cookies.get('user-token');
    const browserWarning = Cookies.get('browser-warning');

    const decode = decoder({
      secret: config.secret,
    });

    var payload = decode(token);

    this.loadProfile(payload.userGroup);

    const browserInfo = this.$options.filters.getBrowserInfo();

    if (browserInfo[0] !== 'Chrome' && browserInfo[1] <= 79) {
      if (browserWarning !== '0') {
        this.$bvModal.show('browser_warning');
      }
    }

    const query = this.$router.currentRoute.query;
    const autoLogin = query.autoLogin;
    if (autoLogin) {
      const navBar = document.getElementById('navbar');
      const navBar2 = document.getElementById('navbar2');
      const navBar3 = document.getElementById('navbar3');

      const mainContent = document.getElementById('mainContent');
      navBar.style.display = 'none';
      navBar2.style.display = 'none';
      navBar3.style.display = 'none';
      mainContent.classList.remove('main-content');
    }
  },
  methods: {
    toggleShowUploadVideo() {
      if (this.$root.$store.state.user.userGroup == 1 || this.$root.$store.state.user.userGroup == 4) return;
      if (this.$store.state.user.enablePrivateVideo) return;
      const uploadParentIndex = this.navItems.findIndex((item) => item.id === 'uploadContent');
      this.navItems[uploadParentIndex].children = this.navItems[uploadParentIndex].children.filter((item) => item.index !== 1);
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('scrollbar-inner');
      }
    },
    loadProfile(userGroup) {
      if (!this.$store.state.user.profileLoaded || this.navItems.length === 0) {
        this.$store.dispatch('user/USER_PROFILE_REQUEST').then((userProfile) => {
          // if (this.currentUserGroup !== 2) {
          this.$store.dispatch('company/GET_COMPANY_LIST').then((resp) => {
            var companyID = Cookies.get('companyID');
            let company = resp.filter((companyVal) => {
              return companyVal.companyID === companyID;
            });

            this.$store.commit('company/SET_SELECTED_COMPANY', companyID);
            this.$store.commit('company/SET_SELECTED_COMPANY_DETAILS', company[0]);
            this.$root.$store.state.user.companyID = companyID;

            let companyStore = this.$store.state.company.company;
            let faviconIcon = '/favicon.ico';

            if (companyStore === null || companyStore === '' || companyStore === undefined) {
              faviconIcon = '/favicon.ico';
            } else {
              faviconIcon = companyStore.favicon + '?=' + Math.random() || companyStore.logo + '?=' + Math.random();
            }

            var favicon = document.querySelectorAll('.favicon');
            for (var i = 0; i < favicon.length; i++) {
              favicon[i].href = faviconIcon;
            }

            document.title = companyStore ? companyStore.name : 'Vobo';

            if (this.$root.$store.state.user.userGroup == 1) {
              let currentUserCompany = this.$root.$store.state.company.company;
              if (currentUserCompany && (currentUserCompany !== null || currentUserCompany !== undefined)) {
                if (currentUserCompany.hasOwnProperty('adminColor')) {
                  Cookies.set('adminColor', currentUserCompany.adminColor.color_hex);
                  this.iconColor = `color: ${currentUserCompany.adminColor.color_hex} !important`;
                } else {
                  Cookies.set('adminColor', '');
                  this.iconColor = '';
                }
              }
            }

            if (resp && company.length > 0) {
              var adminMenu = [];
              var adminMenuIndex = [];
              if (company[0].hasOwnProperty('adminMenu') && company[0].adminMenu.length > 0) {
                adminMenu = company[0].adminMenu || nav.items;

                if (adminMenu.length > 0) {
                  adminMenu.map((menu) => {
                    adminMenuIndex.push(menu.index);
                  });
                  var defaultNavitems = nav.items.filter((menu) => {
                    return !adminMenuIndex.includes(menu.index);
                  });

                  defaultNavitems.map((menu) => {
                    adminMenu.push(menu);
                  });

                  var adminMenuList = adminMenu.filter((menu) => {
                    const adminMenuUserGroups = menu.userGroups.map((item) => Number(item));
                    return adminMenuUserGroups.includes(userGroup);
                  });

                  this.navItems = adminMenuList.sort((a, b) => a.index - b.index);

                  if (this.currentUserGroup === 2) {
                    this.navItems.forEach((item) => {
                      if (item.id === 'followers') {
                        const followersSection = nav.items.find((item) => item.id === 'followers');
                        const imTab = followersSection.children.find((item) => item.name === 'IM');
                        if (imTab) {
                          item.children.push(imTab);
                        }
                      }
                    });
                  }

                  this.toggleShowUploadVideo();
                }
              } else {
                this.navItems = nav.items.filter((menu) => {
                  return menu.userGroups.includes(userGroup);
                });
                this.toggleShowUploadVideo();
              }
            } else {
              this.navItems = nav.items.filter((menu) => {
                return menu.userGroups.includes(userGroup);
              });
              this.toggleShowUploadVideo();
            }
            if (this.navItems.length > 0) {
              this.initScrollbar();
            }

            if (
              userProfile.adminPermissions &&
              'permissions' in userProfile.adminPermissions &&
              userProfile.adminPermissions.permissions === 'messaging-only'
            ) {
              this.messagingOnly = true;
              const ids = ['separator1', 'home', 'dashboard', 'separator2', 'messages', 'sendMessage', 'separator3', 'uploadContent'];
              const filteredNavItems = this.navItems.filter((item) => ids.includes(item.id));
              this.navItems = filteredNavItems.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);
              this.navItems = filteredNavItems;
              if (this.navItems[this.navItems.length - 1].id === 'separator3') {
                this.navItems.pop();
              }
            }
          });
        });
      }
    },
    onCompanyChange(companyID) {
      var message = 'L O A D I N G ...';
      if (companyID) {
        let companyList = this.companyList;
        let company = Vue.lodash.filter(companyList, { companyID: companyID });
        Cookies.set('companyID', companyID);
        this.selectedCompany = companyID;

        this.$store.commit('company/SET_SELECTED_COMPANY', companyID);
        this.$store.commit('company/SET_SELECTED_COMPANY_DETAILS', company[0]);

        message = 'Loading resources of ' + company[0].name;
      } else {
        Cookies.remove('companyID');
        this.selectedCompany = companyID;

        this.$store.commit('company/SET_SELECTED_COMPANY', null);
        this.$store.commit('company/SET_SELECTED_COMPANY_DETAILS', {});
      }

      let company = this.$store.state.company.company;
      let faviconIcon = '/favicon.ico';

      if (this.selectedCompany == null && (company === null || company === '')) {
        faviconIcon = '/favicon.ico';
      } else {
        faviconIcon = company !== null ? company.favicon + '?=' + Math.random() : company.logo;
      }

      if (companyID == null) {
        Cookies.set('adminColor', '');
        this.iconColor = '';
      } else {
        if (Object.keys(company).length > 0) {
          if (company.hasOwnProperty('adminColor')) {
            Cookies.set('adminColor', company.adminColor.color_hex);
            this.iconColor = `color: ${company.adminColor.color_hex} !important`;
          } else {
            Cookies.set('adminColor', '');
            this.iconColor = '';
          }
        }
      }

      var favicon = document.querySelectorAll('.favicon');
      for (var i = 0; i < favicon.length; i++) {
        favicon[i].href = faviconIcon;
      }
      wrapNotification(this.$notify, NotificationTypes.Info, '', message, 10000);

      setTimeout(() => {
        this.$router.go();
      }, 2000);
    },
    showIdle() {
      let timerInterval;
      let username = this.$store.state.user.firstname;
      this.$swal
        .fire({
          position: 'center',
          imageUrl: this.staticUrl + '/img/idle.png',
          imageWidth: 150,
          title: 'Your session has expired',
          html: 'Hi <strong></strong> your being timed out due to inactivity. If you want to continue your session kindly click continue. <b></b>',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'Continue',
          confirmButtonClass: 'btn btn-primary pr-5 pl-5',
          footer:
            '<div class="text-center"><p class="font-sm text-gray mb-0">Your session will automatically ends in <strong id="countoff"></strong> secs.</p></div>',
          buttonsStyling: false,
          allowOutsideClick: false,
          timer: 20000,
          onBeforeOpen: () => {
            this.$swal.getContent().querySelector('strong').textContent = username;
            timerInterval = setInterval(() => {
              this.$swal.getFooter().querySelector('#countoff').innerHTML = (this.$swal.getTimerLeft() / 1000).toFixed(0);
            }, 100);
          },
          onClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result) {
            const { dismiss, value } = result;
            if (!value && dismiss === 'timer') {
              this.logout();
            }
          }
        });
    },
    logout: function () {
      this.$store.dispatch('auth/AUTH_LOGOUT').then(() => this.$router.push('/login'));
    },
    closeWarning() {
      Cookies.set('browser-warning', '0', { expires: 3 });
      this.$bvModal.hide('browser_warning');
    },
  },
  onIdle() {
    this.showIdle();
  },
};
</script>
<style lang="scss">
#companyID {
  color: #172b4d !important;
}
.navbar-brand {
  text-transform: uppercase;
  color: #fff !important;
  font-weight: bold;
  font-size: 18px;
}
.title-with-image {
  position: absolute;
  top: 20px;
  margin-left: 10px;
}
.sidenav .navbar-brand {
  padding: 0.5rem 1rem !important;
}
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.hidden {
  display: none;
}
.ck-content {
  height: 300px;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-copy {
  cursor: copy;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-default {
  cursor: default;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.cursor-help {
  cursor: help;
}
.cursor-move {
  cursor: move;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-none {
  cursor: none;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-text {
  cursor: text;
}
.cursor-w-resize {
  cursor: w-resize;
}
.cursor-wait {
  cursor: wait;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.custom-select {
  color: #525f7f !important;
}
.iconImg img {
  width: 20px;
  margin-right: 12px;
}
</style>
