export default {
  items: [
    {
      separator: true,
      id: 'separator1',
      index: 0,
      userGroups: [1, 2, 4],
    },
    {
      title: true,
      name: 'Home',
      id: 'home',
      index: 1,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-dashboard',
      access: ['dashboard'],
      id: 'dashboard',
      index: 2,
      userGroups: [1, 2, 4],
    },
    {
      separator: true,
      id: 'separator2',
      index: 3,
      userGroups: [1, 2, 4],
    },
    {
      title: 'true',
      name: 'Messages',
      id: 'messages',
      visible: true,
      index: 4,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Send Message',
      url: '/messages/send-notifications',
      icon: 'fa fa-envelope',
      id: 'sendMessage',
      visible: true,
      index: 5,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Message History',
      url: '/messages/history',
      icon: 'ni ni-bullet-list-67',
      access: ['messages'],
      id: 'messageList',
      index: 6,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Message Sequence',
      url: '/messages/sequence/list',
      icon: 'fas fa-layer-group',
      id: 'messageSequence',
      index: 7,
      userGroups: [2],
    },
    {
      separator: true,
      id: 'separator3',
      index: 8,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Upload Content',
      url: '/upload-file/list',
      icon: 'fas fa-upload',
      access: ['audioManager'],
      id: 'uploadContent',
      index: 9,
      userGroups: [1, 2, 4],
    },
    {
      separator: true,
      id: 'separator39',
      index: 11,
      userGroups: [1, 2, 4],
    },
    {
      title: true,
      name: 'Publish Content',
      id: 'Publish Content header',
      visible: true,
      index: 12,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Publish Content',
      access: ['unionLinks'],
      icon: 'fas fa-external-link-square-alt',
      id: 'unionLinks',
      index: 13,
      userGroups: [1, 2, 4],
      children: [
        {
          name: 'App Content (Links)',
          url: '/links',
          icon: 'ni ni-bullet-list-67',
          index: 0,
          userGroups: [1, 2, 4],
        },
        {
          name: 'Content Folders (Banners)',
          url: '/links/types/list',
          icon: 'ni ni-archive-2',
          index: 1,
          userGroups: [1, 2, 4],
        },
      ],
    },
    {
      separator: true,
      id: 'separator3',
      index: 14,
      userGroups: [1, 2, 4],
    },
    {
      title: true,
      name: 'Users',
      id: 'users',
      visible: true,
      index: 15,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Users',
      icon: 'ni ni-circle-08',
      id: 'followers',
      index: 16,
      userGroups: [1, 2, 4],
      children: [
        {
          name: 'List',
          url: '/users',
          icon: 'ni ni-bullet-list-67',
          index: 0,
          userGroups: [1, 2, 4],
        },
        {
          name: 'Groups',
          url: '/users/groups',
          icon: 'fas fa-users',
          index: 1,
          userGroups: [1, 2, 4],
        },
        {
          name: 'Chat (IM) History',
          url: '/users/im',
          icon: 'fas fa-comments',
          index: 2,
          userGroups: [2],
        },
        {
          name: 'Admins',
          icon: 'fas fa-user-lock',
          url: '/user/admins',
          id: 'adminUsers',
          index: 3,
          userGroups: [1, 2, 4],
        },
      ],
    },
    {
      separator: true,
      id: 'separator4',
      index: 18,
      userGroups: [1, 2, 4],
    },
    {
      title: true,
      name: 'Channels',
      id: 'channels',
      index: 19,
      userGroups: [1, 4],
    },
    {
      name: 'Provider Channels',
      icon: 'fas fa-chalkboard-teacher',
      id: 'providerChannels',
      index: 20,
      userGroups: [1, 4],
      children: [
        {
          name: 'List',
          url: '/content-provider/list',
          icon: 'ni ni-bullet-list-67',
          index: 0,
          userGroups: [1, 4],
        },
        {
          name: 'Groups',
          url: '/content-provider/groups',
          icon: 'fas fa-users',
          index: 1,
          userGroups: [1, 4],
        },
        {
          name: 'Channel Categories',
          url: '/content-provider/categories',
          icon: 'ni ni-archive-2',
          index: 2,
          userGroups: [1, 4],
        },
        {
          name: 'Channel Types',
          url: '/content-provider/types',
          icon: 'ni ni-archive-2',
          index: 3,
          userGroups: [1, 4],
        },
      ],
    },
    {
      separator: true,
      id: 'separator5',
      index: 21,
      userGroups: [1, 4],
    },
    {
      title: true,
      name: 'Settings',
      id: 'settings',
      index: 22,
      userGroups: [1, 2, 4],
    },
    {
      name: 'Organizations',
      url: '/organizations',
      icon: 'far fa-building',
      access: ['company'],
      id: 'organization',
      index: 23,
      userGroups: [1],
    },
    {
      name: 'Price Plans',
      url: '/priceplans',
      icon: 'fas fa-external-link-square-alt',
      access: ['company'],
      id: 'priceplan',
      index: 24,
      userGroups: [1, 4],
    },
    {
      name: 'User Subscriptions',
      url: '/subscriptions',
      icon: 'fas fa-receipt',
      access: ['subscripton'],
      id: 'subscripton',
      index: 25,
      userGroups: [1, 4],
    },
    {
      name: 'Member Benefits',
      url: '/packages',
      icon: 'fas fa-portrait',
      id: 'memberBenefits',
      index: 26,
      userGroups: [1, 4],
    },
    {
      name: 'Award Campaign',
      url: '/messages/award-campaigns',
      icon: 'fas fa-bullhorn',
      index: 27,
      userGroups: [1],
    },
    {
      name: 'Settings',
      icon: 'fas fa-cogs',
      access: ['settings', 'mobileSettings'],
      id: 'voboSettings',
      index: 29,
      url: '/other-settings',
      userGroups: [1, 4],
    },
    {
      name: 'Settings',
      icon: 'fas fa-cogs',
      access: ['settings', 'mobileSettings'],
      id: 'voboSettings',
      index: 30,
      userGroups: [2],
      children: [
        {
          name: 'Home Screen Layout',
          url: '/other-settings?setting=home-screen-layout',
          icon: 'fas fa-chalkboard-teacher text-vobo text-icon',
          index: 0,
          userGroups: [2],
        },
        {
          name: 'Display Format',
          url: '/other-settings?setting=display-format',
          icon: 'fas fa-mobile-alt text-vobo text-icon',
          index: 1,
          userGroups: [2],
        },
        {
          name: 'Color Selection',
          url: '/other-settings?setting=color-selection',
          icon: 'fas fa-palette text-vobo text-icon',
          index: 2,
          userGroups: [2],
        },
        {
          name: 'Security',
          url: '/other-settings?setting=security',
          icon: 'fas fa-lock text-vobo text-icon',
          index: 3,
          userGroups: [2],
        },
        {
          name: 'Social Media',
          url: '/other-settings?setting=social-media',
          icon: 'fas fa-share-alt text-vobo text-icon',
          index: 4,
          userGroups: [2],
        },
        {
          name: 'Sub Admins',
          icon: 'fas fa-user-lock',
          url: '/user/admins',
          id: 'adminUsers',
          index: 5,
          userGroups: [2],
        },
        {
          name: 'Invitation Link & QRCode',
          url: '/other-settings/?setting=install-shortcuts',
          icon: 'fas fa-qrcode text-vobo text-icon',
          index: 6,
          userGroups: [2],
        },
        {
          name: 'Tags (Custom Fields)',
          url: '/other-settings/?setting=tags',
          icon: 'fas fa-edit  text-vobo text-icon',
          index: 7,
          userGroups: [2],
        },
        {
          name: 'App Permissions',
          url: '/other-settings/?setting=permissions',
          icon: 'fas fa-user text-vobo text-icon',
          index: 8,
          userGroups: [2],
        },
      ],
    },
    {
      spacer: true,
      id: 'spacer',
      index: 31,
      userGroups: [1, 2, 4],
    },
  ],
};
